/**
 * Dot2Dot is a simple JS class to help developers to embed a drawing map system into a web site.
 * It use Google Maps JavaScript API.
 * It's useful to let the user draw a shape and retrieve the coordinates of its vertices.
 *
 *  @author Francesco Salamone <francesco.salamone@ssd.it>
 *  @class Dot2Dot
 *  @constructor
 */
class Dot2Dot {
  constructor(assigned_name, instanceConf) {
    this.conf = {
      mapCenterLat: 41.8919300,
      mapCenterLng: 12.5113300,
      mapZoom: 13,
      //polyFillColor: '#66BB00',
      polyFillColor: '#FF7F00',
      polyStrokeColor: '#33892d',
      toolsBgColor: '#fafafa',
      title: 'Draw a shape on the map',
      resetText: 'reset the shape',
      confirmText: 'Confirm',
      closeText: 'close'
    };
    this.assigned_name = assigned_name;
    this.instanceId = 'dot2dot-' + Dot2Dot.randomId;
    this.instanceConf = instanceConf;
    this.dialogIsVisible = false;
    this.map = null;
    this.coords = [];
    this.polygonPoints = [];
    this.polygons = [];
    this.drawingManager = null;
    this.destinationId = null;
    if (typeof this.instanceConf === 'object') {
      for (var option in this.instanceConf) {
        if (this.instanceConf.hasOwnProperty(option) && this.conf.hasOwnProperty(option))
          this.conf[option] = this.instanceConf[option];
      }
    }
    this.polygonOptions = {
      paths: this.normalizeCoordsArray(),
      fillColor: this.conf.polyFillColor,
      fillOpacity: 0.6,
      strokeWeight: 3,
      strokeColor: this.conf.polyStrokeColor,
      editable: true,
      clickable: true,
      draggable: true,
      geodesic: false,
    };
    this.bounds = new google.maps.LatLngBounds();
  }

  /**
   * Generate and returns a random string to build ids for the Dom's elements
   *
   * @method randomId
   * @return {string}
   */
  static get randomId() {
    return String.fromCharCode(65 + Math.floor(Math.random() * 26)) + Date.now();
  }

  /**
   * Get current coordinates and generate a MVCArray Object to build a polygon on top of the map
   *
   * @method normalizeCoordsArray
   * @return {google.maps.MVCArray}
   */
  normalizeCoordsArray() {
    var myMvcArray = new google.maps.MVCArray();
    var currentInstance = this;
    for (var x in currentInstance.coords) {
      if (currentInstance.coords.hasOwnProperty(x)) {
        var c = currentInstance.coords[x].split(";");
        myMvcArray.push(new google.maps.LatLng(c[0], c[1]));
      }
    }

    return myMvcArray;
  }

  updatePolygonOptions() {
    var currentInstance = this;
    var options = currentInstance.polygonOptions;
    options.paths = currentInstance.normalizeCoordsArray();
    currentInstance.polygonOptions = options;
  }

  init() {
    var currentInstance = this;
    currentInstance.map = new google.maps.Map(document.getElementById(currentInstance.instanceId + '-mapBox'), {
      center: {
        lat: currentInstance.conf.mapCenterLat,
        lng: currentInstance.conf.mapCenterLng,
      },
      zoom: currentInstance.conf.mapZoom,
      streetViewControl: false,
    });

    if (currentInstance.drawingManager !== null) currentInstance.drawingManager.setMap(null);
    currentInstance.updatePolygonOptions();
    currentInstance.drawingManager = new google.maps.drawing.DrawingManager({
      drawingMode: google.maps.drawing.OverlayType.POLYGON,
      drawingControl: false,
      polygonOptions: currentInstance.polygonOptions
    });
    currentInstance.drawingManager.setMap(null);

    if (currentInstance.coords.length > 0) {
      var currentShape = new google.maps.Polygon(currentInstance.polygonOptions);
      currentShape.setMap(currentInstance.map);
      currentInstance.polygons = [];
      currentInstance.polygonPoints = [];
      currentInstance.coords = [];
      currentInstance.setCoords(currentShape);
      currentInstance.polygons.push(currentShape);
    } else {
      currentInstance.drawingManager.setMap(currentInstance.map);
    }

    google.maps.event.addListener(currentInstance.drawingManager, 'polygoncomplete', function (polygon) {
      currentInstance.setCoords(polygon);
      currentInstance.polygons.push(polygon);
      currentInstance.drawingManager.setDrawingMode(null);
    });
  }

  /**
   * Save into the instance a completed polygon and activate listeners to the update events
   *
   * @method setCoords
   * @param polygon
   */
  setCoords(polygon) {
    var currentInstance = this;
    if (polygon.getPaths().getLength() > 0) {
      var polygons = polygon.getPaths();
      polygons.forEach(function (shape) {
        currentInstance.polygonPoints = shape.getArray();
        for (var i = 0; i < currentInstance.polygonPoints.length; i++) {
          currentInstance.coords.push(currentInstance.polygonPoints[i].lat() + ";" + currentInstance.polygonPoints[i].lng());
          currentInstance.updateBounds(currentInstance.polygonPoints[i].lat(),currentInstance.polygonPoints[i].lng());
        }

        google.maps.event.addListener(shape, 'set_at', function (x) {
          currentInstance.updateCoord(x);
        });
        google.maps.event.addListener(shape, 'insert_at', function (x) {
          currentInstance.addCoord(x);
        });
        google.maps.event.addListener(shape, 'remove_at', function (x) {
          currentInstance.removeCoord(x);
        });
      });

      currentInstance.updateCoords();
    }
  }

  resetCoords() {
    var currentInstance = this;
    var selectedPoints = document.getElementById(currentInstance.instanceId + "-selectedCoords");
    if (selectedPoints) selectedPoints.innerHTML = '';
    currentInstance.coords = [];
  }

  updateCoords() {
    var currentInstance = this;
    var selectedPoints = document.getElementById(currentInstance.instanceId + "-selectedCoords");
    selectedPoints.innerHTML = '';
    for (var i = 0; i < currentInstance.coords.length; i++) {
      selectedPoints.innerHTML += '<input type="hidden" name="coords[' + i + ']" value="' + currentInstance.coords[i] + '" size="50"/>' + "\n";
    }
  }

  updateCoord(x) {
    var currentInstance = this;
    if (x !== null) currentInstance.coords[x] = currentInstance.polygonPoints[x].lat() + ";" + currentInstance.polygonPoints[x].lng();
    currentInstance.updateCoords();
  }

  addCoord(x) {
    var currentInstance = this;
    if (x !== null) currentInstance.coords.splice(x, 0, currentInstance.polygonPoints[x].lat() + ";" + currentInstance.polygonPoints[x].lng());
    currentInstance.updateCoords();
  }

  removeCoord(x) {
    var currentInstance = this;
    if (x !== null) currentInstance.coords.splice(x, 1);
    currentInstance.updateCoords();
  }

  showDialog(destinationId) {
    var currentInstance = this;
    currentInstance.destinationId = destinationId;
    var currentCoords = document.getElementById(destinationId);
    if (currentCoords.value.length > 0) {
      currentInstance.resetCoords();
      currentInstance.coords = JSON.parse(atob(currentCoords.value));
    }

    var currentMapCenter = document.getElementById(destinationId + "_center");
    if (currentMapCenter.value.length > 0) {
      currentMapCenter = currentMapCenter.value.replace(/[\(\)\s]/g,'').split(',');
      currentInstance.conf.mapCenterLat = parseFloat(currentMapCenter[0]);
      currentInstance.conf.mapCenterLng = parseFloat(currentMapCenter[1]);
    }

    var currentMapZoom = document.getElementById(destinationId + "_zoom");
    if (currentMapZoom.value.length > 0) {
      currentInstance.conf.mapZoom = parseInt(currentMapZoom.value);
    }

    if (!currentInstance.dialogIsVisible) {
      var overlay = document.createElement('div');
      overlay.setAttribute('id', currentInstance.instanceId + '-overlay');
      overlay.setAttribute('class', 'dot2dot-overlay');

      var dialog = document.createElement('div');
      dialog.setAttribute('id', currentInstance.instanceId + '-dialog');
      dialog.setAttribute('class', 'dot2dot-dialog');

      var tools = document.createElement('div');
      tools.setAttribute('id', currentInstance.instanceId + '-tools');
      tools.setAttribute('class', 'dot2dot-tools');
      tools.style.cssText = 'background-color: ' + currentInstance.conf.toolsBgColor + ';';

      var title = document.createElement('h2');
      title.setAttribute('class', 'dot2dot-title');
      title.setAttribute('style', 'color: ' + currentInstance.conf.polyStrokeColor);
      title.innerHTML = currentInstance.conf.title;

      var userGuide = document.createElement('ol');
      userGuide.setAttribute('class','dot2dot-user-guide-steps');

      var guideItems = [
        'Aggiungi punti sulla mappa',
        'Chiudi la figura',
        'Modifica la figura',
      ];

      var guideItemsTexts = [
        'Fai click sulla mappa per aggiungere punti alla figura',
        'Chiudi la figura facendo click sul primo punto',
        'Modifica la figura spostando i punti o aggiungine di nuovi spostando quelli intermedi.',
      ];

      for (var i = 0;i < guideItems.length;i++) {
        var userGuideItem = document.createElement('li');
        userGuideItem.setAttribute('class','dot2dot-user-guide-step dot2dot-user-guide-step-' + i);
        userGuideItem.innerHTML = '<b>' + guideItems[i] + '</b><br />';
        userGuideItem.innerHTML += guideItemsTexts[i];
        userGuide.appendChild(userGuideItem);
      }

      var legend = document.createElement('div');
      legend.setAttribute('id', currentInstance.instanceId + '-legend');
      legend.setAttribute('class', 'dot2dot-legend');

      var selectedPoints = document.createElement('div');
      selectedPoints.setAttribute('id', currentInstance.instanceId + '-selectedCoords');

      var actionButtons = document.createElement('div');
      actionButtons.setAttribute('id', currentInstance.instanceId + '-actionBtns');
      actionButtons.setAttribute('class', 'dot2dot-action-buttons');

      var doneBtn = document.createElement('a');
      doneBtn.setAttribute('id', currentInstance.instanceId + '-doneBtn');
      doneBtn.setAttribute('class', 'dot2dot-btn dot2dot-btn-done');
      doneBtn.setAttribute('href', 'javascript:void(0);');
      doneBtn.setAttribute('style', 'background-color: ' + currentInstance.conf.polyStrokeColor);
      doneBtn.setAttribute('onclick', currentInstance.assigned_name + '.confirmPolygon();');
      doneBtn.innerHTML = currentInstance.conf.confirmText;

      var cancelBtn = document.createElement('a');
      cancelBtn.setAttribute('id', currentInstance.instanceId + '-cancelBtn');
      cancelBtn.setAttribute('class', 'dot2dot-btn dot2dot-btn-cancel');
      cancelBtn.setAttribute('href', 'javascript:void(0);');
      cancelBtn.setAttribute('onclick', currentInstance.assigned_name + '.clearPolygon();');
      cancelBtn.innerHTML = currentInstance.conf.resetText;

      actionButtons.appendChild(cancelBtn);
      actionButtons.appendChild(doneBtn);

      var mapBox = document.createElement('div');
      mapBox.setAttribute('id', currentInstance.instanceId + '-mapBox');
      mapBox.setAttribute('class', 'dot2dot-map-box');

      var closeBtn = document.createElement('a');
      closeBtn.setAttribute('id', currentInstance.instanceId + '-closeBtn');
      closeBtn.setAttribute('class', 'dot2dot-close-btn');
      closeBtn.setAttribute('href', 'javascript:void(0);');
      closeBtn.setAttribute('onclick', currentInstance.assigned_name + '.hideDialog();');
      closeBtn.setAttribute('style', 'color: ' + currentInstance.conf.polyStrokeColor);
      closeBtn.innerHTML = currentInstance.conf.closeText;

      tools.appendChild(title);
      legend.appendChild(userGuide);
      tools.appendChild(legend);
      tools.appendChild(selectedPoints);
      tools.appendChild(actionButtons);
      dialog.appendChild(tools);
      dialog.appendChild(mapBox);
      dialog.appendChild(closeBtn);
      overlay.appendChild(dialog);
      document.body.appendChild(overlay);
      currentInstance.dialogIsVisible = true;

      currentInstance.init();
    }
  }

  clearPolygon() {
    var currentInstance = this;
    for (var i = 0; i < currentInstance.polygons.length; i++) {
      currentInstance.polygons[i].setMap(null);
    }
    currentInstance.polygons = [];
    currentInstance.resetCoords();
    currentInstance.init();
    currentInstance.drawingManager.setDrawingMode(google.maps.drawing.OverlayType.POLYGON);
  }

  updateBounds(lat,lng) {
    //var currentInstance = this;
    //var loc = new google.maps.LatLng(lat, lng);
    //currentInstance.bounds.extend(loc);
    //currentInstance.map.fitBounds(currentInstance.bounds);
    //currentInstance.map.panToBounds(currentInstance.bounds);
  }

  confirmPolygon() {
    var currentInstance = this;
    var dest = document.getElementById(currentInstance.destinationId);
    var currentCenter = document.getElementById(currentInstance.destinationId + "_center");
    currentCenter.value = currentInstance.map.getCenter();
    var currentZoom = document.getElementById(currentInstance.destinationId + "_zoom");
    currentZoom.value = currentInstance.map.getZoom();
    dest.value = btoa(JSON.stringify(currentInstance.coords));
    dest.onchange();
    currentInstance.hideDialog();
  }

  hideDialog() {
    var currentInstance = this;
    if (currentInstance.dialogIsVisible) {
      var overlay = document.getElementById(currentInstance.instanceId + '-overlay');
      overlay.remove();

      currentInstance.dialogIsVisible = false;
    }
  }
}